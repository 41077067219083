<template>
    <div>
        <template v-if="detail">

            <div class="page-title">{{ detail.title }}</div>

            <div class="helper-content" v-html="detail.content"></div>
        </template>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Detail",
    data() {
        return {
            detailId: 0,
            detail: null,
        }
    },
    mounted() {
        this.detailId = this.$route.params.id;
        this.getDetail()
    },
    methods: {
        getDetail() {
            this.$loading.show()

            api.get('/ast-app/book-detail', {id: this.detailId}, res => {

                this.$loading.hide()


                if (!res.data) {
                    this.$message.error('暂无数据')
                    this.$router.back()
                }

                this.detail = res.data
            })
        }
    }
}
</script>

<style scoped lang="less">
.helper-content {
    width: 900px;
    margin-bottom: 68px;
    /deep/ img{
        max-width: 500px !important;
    }
}
</style>